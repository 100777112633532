header {
    box-shadow: 0px 6px 12px 0px rgba(140, 152, 164, 0.08);
}
.custom-margin{
    margin-left: 10px;
}
@media (min-width:1920px) {
    .custom-margin{
        margin-left: -49px !important;
    }
}
