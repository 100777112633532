.dashboard-main-div .react-tabs .react-tabs__tab--selected {
    color: #377DFF !important;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    background-color: #FFF !important;
    border-radius: 0 !important;
    border-bottom: 3px solid #377DFF !important;
    padding: 22px 16px !important;
    margin-bottom: 0px !important;
    box-shadow: none !important;
}

.dashboard-main-div .react-tabs .main-border {
    border-bottom: 2px solid #EFF1F7;
    margin-bottom: -2px;

}

.dashboard-main-div .react-tabs .react-tabs__tab,
.dashboard-main-div .react-tabs .react-tabs__tab:nth-child(1) {
    color: #BDC5D1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    /* text-wrap: nowrap; */
    /* border: none !important;  */
    box-shadow: none !important;
    padding: 22px 16px !important;

    /* border-bottom: 2px solid #EFF1F7; */
}

.dashboard-checklist-graph .sub-div,
.parameter-comparision .sub-div,
.alert-notification-div .sub-div,
.dashboard-graph .sub-div {
    border-radius: 6px;
    border: 1px solid #EFF1F7;
    background: #FFF;
    box-shadow: 0px 6px 12px 0px rgba(140, 152, 164, 0.08);
}

.dashboard-checklist-graph .graph-div div {
    width: 100% !important;
    /* height: auto !important; */
}

.dashboard-checklist-graph .graph-div {
    height: 99px !important;
}

.dashboard-checklist-graph .graph-div canvas {
    width: 100% !important;
    height: 95px !important;
    /* margin-right: 16px !important; */
}


.select-div .select-field {
    padding: 0px 15px;
    background: #FFF url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(89% + 3px) center / 12px 5px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000;
    border-radius: 5.062px;
    border: 1.274px solid var(--border-0, #EBECF0);

}

.alert-notification-div .dashboard-custom-height {
    height: 67.4vh;
}
.dashboard-checklist-graph .carousel{
    width: 0;
}
.dashboard-checklist-graph .carousel .control-dots{
    display: none !important;
}
.dashboard-checklist-graph .carousel.carousel-slider .control-arrow.control-next,.dashboard-checklist-graph .carousel.carousel-slider .control-arrow.control-prev,
.dashboard-checklist-graph .carousel.carousel-slider .control-arrow.control-next:hover, .dashboard-checklist-graph.carousel.carousel-slider .control-arrow.control-prev:hover{
background-color: transparent !important;
top: 37px !important;
padding: 0px !important;
height: 38px;
width: 37px;
border-radius: 100%;
}

.carousel .control-next.control-arrow:before{
    border-left: 16px solid #000;
    margin: 0px; 
    border-top: 14px solid transparent;
    border-bottom: 15px solid transparent;

}
.carousel .control-prev.control-arrow:before{
    border-right: 16px solid #000;
    margin: 0px; 
    border-top: 14px solid transparent;
    border-bottom: 15px solid transparent;
}
/* .dashboard-checklist-graph .carousel.carousel-slider .control-arrow.control-prev,.dashboard-checklist-graph .carousel.carousel-slider .control-arrow.control-next {
   
    top: 0 !important;
   
} */
.dashboard-main-div .slick-slide.slick-active,.dashboard-main-div .slick-slide{
    width:auto !important;  
    /* padding: 22px 16px !important; */
}

.slick-slider {
    /* background-color: #377DFF; */
    width: 97% !important;
}
.dashboard-main-div .slick-arrow{
    border: 1px solid transparent !important;
    border-radius: 100% !important;
}
.dashboard-main-div .slick-prev:before, .dashboard-main-div .slick-next::before{
   color: #7b7e81;
}


@media (min-width:1440px) and (max-width:1534px) {
    .alert-notification-div .dashboard-custom-height {
        height: 59.8vh;
    }

    .paramter-popup .echarts-for-react canvas {
        height: 594px !important;
    }

    .parameter-list-height {
        height: 59vh !important;
    }
}


@media (min-width:1920px) {
    .alert-notification-div .dashboard-custom-height {
        height: 48.8vh;
    }

    .ReactModal__Content {
        height: 840px !important;
    }

    .paramter-popup .echarts-for-react canvas {
        height: 720px !important;
    }
}
