.code-compliance,
.calculation-method {
    font-family: inter !important;

}

/**** style for code-compliance starts here *****/
/* .code-compliance h1,
.calculation-method h1,
.code-compliance-chart h1 {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FFF !important;
    padding-top: 0px;
} */

.code-compliance .green-div {
    position: relative;
    height: 17%;
    min-height: 10px;
    width: 10px;

}

.red-div {
    position: relative;
    height: 17%;
    min-height: 10px;
    width: 10px;
    background-color: #FF4C4C;
}

.code-compliance .rdt_Pagination {
    display: none !important;
}

.i-btn {
    border-radius: 13332px;
    background: #FFF;
    box-shadow: 0px 1.333px 4px 0px rgba(0, 0, 0, 0.10), 0px 1.333px 2.667px -1.333px rgba(0, 0, 0, 0.10);
    color: #111827;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24px;
    /* 150% */
}

/* .mapview .code-compliance .rdt_TableBody,
.mapview .code-compliance .rdt_TableRow,
.mapview .code-compliance .rdt_TableRow:nth-child(even) {
    background-color: rgba(68, 68, 79, 0.70) !important;
    color: #FFF;
    font-size: 7.452px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

/* .mapview .code-compliance .rdt_TableHeadRow {
    background-color: rgba(94, 94, 112, 0.97) !important;
    color: #FFF;
    font-size: 7.452px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
} */

.code-compliance .rdt_TableRow:hover {
    color: #FFF !important;
}

.code-compliance .hSDeSX:hover,
.code-compliance .gIkJmC:hover {
    outline: none !important;
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    /* outline-style: none !important; */
    /* outline-color: transparent !important; */
}

.code-compliance .rdt_TableHeadRow {
    border-top: none !important;
}

.code-compliance .rdt_TableHeadRow .rdt_TableCol {
    border-top: none !important;
    padding-left: 7px !important;
}

.code-compliance .rdt_TableBody {
    height: 17vh !important;
}

.code-compliance .rdt_TableRow {
    min-height: 20px !important;
}

.dashboard .code-compliance .rdt_TableHeadRow {
    min-height: 20px !important;
}

.mapview .code-compliance .rdt_TableHeadRow {
    min-height: 20px !important;
    /* margin-top: -4px !important; */
    /* padding-top: 9px; */
    /* margin-bottom: -10px; */
}

.mapview .code-compliance .rdt_TableRow {
    outline: none !important;
    border-bottom: none !important;
}

/**** style for calculation-method starts here *****/
.calculation-method {
    color: #FFF !important;
    /* height: 20vh !important; */
}

/************ main tab ***********/

/* Style the tab */
.screen-monitoring .tab {
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    justify-content: space-around;
}


.screen-monitoring .tab .tablinks {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    text-align: center;
    /* padding: 20px 50px; */
    cursor: pointer;
    /* color: #2C2F34; */
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 3px;
    /* 150% */
    /* text-transform: capitalize; */
}

.screen-monitoring .tab .tablinks.active {
    border-radius: 13332px;
    /* background: #1784EB; */
    box-shadow: 0px 1.333px 4px 0px rgba(0, 0, 0, 0.10), 0px 1.333px 2.667px -1.333px rgba(0, 0, 0, 0.10);
    /* color: #FFF; */
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    margin: 3px;
}

/* Style the tab content */
.screen-monitoring .tabcontent {
    display: none;
    /* padding: 0px 12px; */
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
}

.screen-monitoring .tabcontent.active {
    transition: display 2s ease-in-out;
    display: block;
}


/************* dashboard css ****************/
.dashboard .calculation-method,
.dashboard .bg-row,
.dashboard .code-compliance,
.dashboard .code-compliance .rdt_TableBody,
.dashboard .code-compliance .rdt_TableRow,
.dashboard .code-compliance .rdt_TableRow:nth-child(even),
.dashboard .code-compliance .rdt_TableHeadRow,
.dashboard .code-compliance-chart {
    background-color: #FFF !important;
    color: #333 !important;
    font-size: 12px !important;
}

.dashboard h1,
.dashboard .code-compliance .rdt_TableRow:hover {
    color: #333 !important;
}

.dashboard .calculation-method .main-row:nth-child(even),
.dashboard .calculation-method .main-row:nth-child(even) .bg-row {
    border-radius: 7.645px;
    background-color: #f2f2f2 !important;
}

.dashboard .calculation-method .main-row {
    height: 42px;
}

.dashboard .calculation-method .mapview-height {
    margin-top: -15px;
    height: 29vh;
    /* overflow-y: scroll; */
}

/* .dashboard .code-compliance h1,
.dashboard .calculation-method h1,
.dashboard .code-compliance-chart h1,
.dashboard .imapct-analysis h1,
.mapview .code-compliance h1,
.mapview .calculation-method h1,
.mapview .code-compliance-chart h1,
.mapview .imapct-analysis h1 {
    font-family: inter !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

.code-compliance .rdt_TableCell,
.mapview .code-compliance .rdt_TableCell {
    width: 112px !important;
    display: block !important;
}

.dashboard .code-compliance-chart .dashboard-bg-red {
    border: 1px solid transparent;
    border-radius: 2.878px;
    background-color: #FDECEF;
    width: 23px;
    height: 23px;
    color: #EF746D;
}


.dashboard .code-compliance-chart .dashboard-bg-blue {
    border: 1px solid transparent;
    border-radius: 2.878px;
    background-color: #E1EDFF;
    width: 23px;
    height: 23px;
    color: #1784EB;
}

.dashboard .code-compliance-chart .dashboard-bg-green {
    border: 1px solid transparent;
    border-radius: 2.878px;
    background-color: #E5F3DD;
    width: 23px;
    height: 23px;
    color: #429482;
}

.dashboard .code-compliance-chart .mapview-height {
    border-radius: 5.062px;
    border: 1.274px solid var(--border-0, #EBECF0);

}

/* .dashboard .code-compliance-chart .code-compliance-chart-arrow{
    border-radius: 5.062px;
    border: 1.274px solid var(--border-0, #EBECF0);
} */
.dashboard .code-compliance-chart .chart-text {
    position: absolute;
    top: 5.5rem;
    left: 6.5rem;
}

.dashboard .impact-analysis .tab .tablinks {
    margin-bottom: 4px !important;
    font-family: inter !important;
}

.dashboard .code-compliance-chart-arrow {
    padding: 0px 15px;
    background: #FFF url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(93% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #000;
    border-radius: 5.062px;
    border: 1.274px solid var(--border-0, #EBECF0);

}

.dashboard .code-compliance .rdt_TableRow {
    margin-bottom: 4px !important;
}

/* .dashboard .map-dashboard .mapboxgl-canvas-container canvas {
    width: 668px !important;
    height: 368px !important;
} */
.mapview .mapboxgl-canvas-container canvas {
    width: 100% !important;
    height: 100% !important;
}

/*************** mapview css  starts here*********************/



.mapview .imapct-analysis h1,
.mapview .code-compliance-chart .mapview-text-white {
    color: #FFF !important;
}

.mapview .code-compliance-chart .chart-text {
    position: absolute;
    top: 4.7rem !important;
    left: 4.1rem !important;
}

.mapview .imapct-analysis .tab {

    border: 1px solid #535459;
    background-color: rgba(53, 52, 58, 0.31) !important;
}

.mapview .imapct-analysis .tab .tablinks.active {
    background-color: #4F5058 !important;
    color: #FFF !important;
}

.mapview .impact-analysis .tab .tablinks {
    color: #FFF !important;
    font-family: inter !important;
    margin-bottom: 4px;
}

/* .mapview .calculation-method .main-row {
    height:auto;
    margin-bottom: 0px;
} */

/* .mapview .calculation-method .mapview-height {
    height: 16vh;
    overflow-y: scroll;

} */



/* .mapview .code-compliance{
    height: 169px !important;

} */
/* .mapview .mapviewsm .code-compliance .mapviw-style .ktjKdI{
    min-width: 46px !important;
} */
/* .mapview .code-compliance .rdt_TableBody {
    height: 13vh !important;

} */

.mapview .mapviewsm {
    font-family: inter !important;
}

/* .mapview .code-compliance .mapviw-style  .jmBpSP {
padding-right: 0px !important;
padding-left: 23px !important;
} */
/* .mapview .code-compliance .mapviw-style .cQhyQG {
    padding-right: 0px !important;
    padding-left: 6px !important;
} */
/* .mapview .code-compliance .mapview-text-white {
    color: #FFF !important;
} */

/* .mapview .calculation-method,
.mapview .code-compliance {
    height: 23vh;
}

.mapview .code-compliance-chart {
    height: 27vh !important;
} */

/* .mapview .code-compliance .rdt_TableRow,
.mapview .code-compliance .rdt_TableRow:nth-child(even),
.mapview .code-compliance .rdt_TableHeadRow {

    font-size: 12px !important;
} */

.mapview .code-compliance-chart canvas {
    /* top: -14px !important; */
    /* margin-bottom: 50px;
padding-bottom: -50px !important; */
}

/* .mapview .code-compliance-chart .mapview-height {
    height: 17.5vh !important;
    border-radius: 2.239px;
    border: 0.564px solid rgba(235, 236, 240, 0.08);
    background: rgba(255, 255, 255, 0.05);

} */

/* .mapview .code-compliance-chart .mapview-text-white {
    color: #FFF !important;
} */

.mapview .code-compliance-chart .mapview-bg-gray {
    border: 1px solid transparent;
    border-radius: 2.878px;
    background-color: rgba(125, 125, 133, 0.45);
    width: 23px;
    height: 23px;

}
/* 
.mapview .code-compliance-chart,
.mapview .calculation-method,
.mapview .code-compliance,
.mapview .imapct-analysis {
    background-color: rgba(68, 68, 79, 0.60) !important;
} */

.mapview .code-compliance-chart-arrow {
    padding: 0px 15px;
    background: rgba(68, 68, 79, 0.60) url(../../../assets/images/white-down-arrow.svg) no-repeat scroll calc(86% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #FFF;

}

.mapview .select-field-monitoring {
    padding: 0px 15px;
    background: rgba(68, 68, 79, 0.60) url(../../../assets/images/white-down-arrow.svg) no-repeat scroll calc(90% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #FFF;
}

.mapview .calculation-method .main-row {
    /* border-radius: 3.799px; */
    /* background: rgba(89, 89, 98, 0.45); */
}

.mapview .div-red,
.mapview .div-amber,
.mapview .div-green {
    width: 15px;
    height: 16px;
    border-radius: 5px;
}

.mapview .div-red {
    background-color: #F12E52;
}

.mapview .div-amber {
    background-color: #FF8700;
}

.mapview .div-green {
    background-color: #4CB275;
}

.mapview .code-compliance-chart .custom-tooltip {
    /* position: absolute; */
    /* right: 90%; */
    margin-left: 90%;

}

.gpt-shadow {
    border-radius: 6px;
    box-shadow: 0px 15px 24px 1px rgba(0, 0, 0, 0.04);
}

.light-shadow {
    box-shadow: -4px 1px 20px 3px rgba(0, 0, 0, 0.22);
}

/****** no data section ****/
.code-compliance .noDataSection {
    margin-top: 0rem !important;
}

.code-compliance .noDataSection {
    height: 12vh !important;
}

.mapview .code-compliance .noDataSection {
   
    /* background-color: rgba(68, 68, 79, 0.60) !important; */
    width: 100%;
}
.mapview .rdt_Table .vyRRA{
    /* background-color:rgba(68, 68, 79, 0.99) !important; */
}
.code-compliance .noDataSection h2 {
    font-size: 15px !important;
    margin-top: 40px !important;
}

.code-compliance .noDataSection p,
.code-compliance .noDataSection img,
.code-compliance .noDataSection .userCreated {
    /* font-size: 9px !important; */
    /* margin-top: -20px; */
    display: none !important;
}

/* .code-compliance .noDataSection .monitoring-screen{
    margin-bottom: -10px !important;
} */
.code-compliance .noDataSection {
    gap: 0px !important;
}

.mapview .code-compliance .noDataSection .heading-hidden {
    display: block;
    padding-top: 20px;
    color: #FFF;
}
.dashboard .code-compliance .noDataSection .heading-hidden{
    color: #000 !important;
    display: block;
    padding-top: 30px;
    
}

.mapview .response-bg {
    background-color: rgba(106, 106, 120, 0.9) !important;
}
