.app-header .content-width {
    width: calc(100% - 250px);
    height: 90vh;
    overflow-y: auto;
}
.app-header .collapsed-width{
    width: calc(100% - 80px);
    overflow-y: auto;
    height: 90vh;
}
.app-header .mapview-width{
    width: calc(100%);
    height: 100vh;
    padding: 0 !important;
}
.app-header .padding-div{
    padding: 0 !important;
}
.app-header2 .content-width {
    width: calc(100% - 250px);
    /* height: 90vh;
    overflow-y: auto; */
}
