.ps-sidebar-root {
    border-right: 0px !important;
    box-shadow: 6px 6px 12px 0px rgba(140, 152, 164, 0.08);
}

.ps-active {
    border-radius: 6px;
    background: #377DFF;
    font-weight: 600;
}

.css-1654oxy>.ps-menu-button:hover {
    background-color: #377DFF !important;
    border-radius: 6px !important;
}

.ps-active span {
    color: #ffffff;
}

.ps-menu-label {
    font-size: 15px;
}

.css-ewdv3l li {
    margin-bottom: 4px;
}

.css-1654oxy>.ps-menu-button {
    height: 50px !important;
    padding-left: 7px !important;
}

.css-1t8x7v1>.ps-menu-button {
    height: 53px !important;
    padding-left: 7px !important;
}

.css-wx7wi4 {
    margin-right: 4px !important;
}

.css-dip3t8 {
    background-color: #FFF !important;
}

/* map-sidebar */
/* .map-sidebar .css-ewdv3l li {
    margin-bottom: 4px;
} */
/* .map-sidebar .css-1654oxy>.ps-menu-button {
    height: 37px !important;
    padding-left: 7px !important;
} */
.map-sidebar .css-1t8x7v1>.ps-menu-button {
    height: 40px !important;

}

.map-sidebar .css-1wvake5 {
    height: 100vh !important;
}

.map-sidebar .active-btn {
    background-color: #E1EDFF !important;
    border-radius: 6px !important;
}

.map-sidebar .active-btn a:hover {
    background-color: #E1EDFF !important;
    border-radius: 6px !important;
}

.map-sidebar .ps-menu-label {
    color: #132144;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

#rolemanagement.ps-active,#view-schedule.ps-active {
    background-color: #377DFF !important;
    color: #FFF !important;
    border-radius: 6px !important;
}

#project.ps-active,
#Test.ps-active {
    background-color: #377DFF !important;
    color: #FFF !important;
    border-radius: 6px !important;
}

.last-list {
    position: absolute !important;
    top: 81vh !important;
}

.checklist-nav a {
    cursor: default !important;
}

.checklist-nav a:hover {
    background-color: white !important;
}

@media (min-width:1920px) {
    .last-list {
        top: 85vh !important;
    }
}
