.screen-monitoring .project-text {
    color: var(--Components-Breadcrumb-Component-linkColor, rgba(0, 0, 0, 0.45));
}

.mapview .overlay-div {
    background: linear-gradient(180deg, rgba(15, 15, 20, 0.75) 3.5%, rgba(15, 15, 20, 0.00) 42.19%, rgba(15, 15, 20, 0.00) 73.82%), linear-gradient(358deg, rgba(15, 15, 20, 0.75) 1.31%, rgba(15, 15, 20, 0.00) 38.68%), linear-gradient(270deg, rgba(15, 15, 20, 0.75) 0%, rgba(15, 15, 20, 0.00) 35.59%, rgba(15, 15, 20, 0.00) 60.79%), linear-gradient(90deg, rgba(15, 15, 20, 0.75) 0%, rgba(15, 15, 20, 0.00) 0.01%);

}

.screen-monitoring .dashboard-text {
    color: var(--Components-Breadcrumb-Component-lastItemColor, rgba(0, 0, 0, 0.88));
}

.screen-monitoring .react-tabs .react-tabs__tab--selected:nth-child(1) {
    background-color: #377DFF;
    color: #FFFFFF;
}

.screen-monitoring .react-tabs .react-tabs__tab:nth-child(1) {
    color: #FFFFFF;
}

.screen-monitoring .react-tabs .react-tabs__tab {
    padding: 5.5px 16.34px 5.5px 15px;
    margin: 0 4px 2px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: capitalize;
}

.screen-monitoring .react-tabs .react-tabs__tab--selected {
    border: none;
    border-radius: 9999px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
    padding: 5.5px 16.34px 5.5px 15px;
}

/* .screen-monitoring .react-tabs .first-tab .react-tabs__tab {
cursor:not-allowed;
} */

.screen-monitoring .react-tabs__tab {
    border: none !important;
    /* bottom: 0px !important;
    position: relative; */
}

.screen-monitoring .react-tabs__tab:focus:after {

    position: relative;
    /* height: 5px;
    left: -4px;
    right: -4px;
    bottom: -11px !important; */

}

.screen-monitoring .mapview .react-tabs .react-tabs__tab--selected:nth-child(1) {
    background-color: #FFF !important;
    color: #35354B !important;
}

.screen-monitoring .mapview .react-tabs .react-tabs__tab {
    cursor: pointer !important;
}

.code-compliance h1,
.calculation-method h1,
.code-compliance-chart h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 150% */
}

.tbl-body tr:nth-child(even) {
    background: transparent !important;
}

/***************************** style for Code-compliance starts here *******************************/
.code-compliance h1,
.calculation-method h1,
.code-compliance-chart h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 150% */
}

.code-compliance .rdt_TableBody,
.code-compliance .rdt_TableRow,
.code-compliance .rdt_TableRow:nth-child(even) {
    background-color: #222230 !important;
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
}

.code-compliance .noDataSection {
    background-color: #222230 !important;
    color: #FFF;
}

.code-compliance .custom-green {
    background: rgba(15, 202, 122, 0.58);
}

.code-compliance .rdt_TableBody {
    height: 20vh !important;
}

.code-compliance .rdt_Table {
    background: #222230;
}

.code-compliance .rdt_TableHeadRow {
    border-radius: 6px;
    border: #4A4A68 !important;
    background-color: #4A4A68 !important;
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 200 !important;
    line-height: normal;
    letter-spacing: 0.5px;
    min-height: 27px !important;

    /* padding: -4px 0px ; */
}

.code-compliance .rdt_TableHeadRow .lbIPMj {
    text-transform: capitalize !important;
}

.code-compliance .rdt_TableHead {
    /* border-radius: 4px; */
    /* border: #4A4A68 !important; */
    /* background: #4A4A68 !important; */
    /* height: 27px !important; */
    @apply rounded bg-[#4A4A68] py-1.5 pl-4

}



.code-compliance .rdt_Table .vyRRA {
    background-color: #222230 !important;
    display: flex;
    justify-content: center;
}

.code-compliance .rdt_TableCol_Sortable {
    overflow: visible !important;
}

.screen-monitoring .code-compliance .rdt_TableRow:hover {
    color: #FFF !important;
}

.screen-monitoring .code-compliance .hSDeSX:hover,
.screen-monitoring .code-compliance .gIkJmC:hover {
    outline: none !important;
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    /* outline-style: none !important; */
    /* outline-color: transparent !important; */
}

.code-compliance .rdt_TableHeadRow .rdt_TableCol {
    padding-right: 0px !important;
    padding-left: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    /* padding: 0px 5px; */
}

.code-compliance .rdt_TableHeadRow .rdt_TableCol {
    border: none !important;
    max-width: 100% !important;
    min-width: 68px !important;
}

.code-compliance .rdt_TableBody {
    height: 18vh !important;
}

.code-compliance .noDataSection h2 {
    font-size: 15px !important;
    margin-top: 40px !important;
}

.code-compliance .rdt_TableHead {
    width: 100% !important;

}

/***************************** style for Code-compliance-chart starts here *******************************/
.code-compliance-chart-arrow {
    padding: 0px 15px;
    background: #4A4A68 url(../../../assets/images/white-down-arrow.svg) no-repeat scroll calc(86% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #FFF;

}

.code-compliance-chart .custom-tooltip {
    /* margin-left: 100% !important; */
}

/***************************** style for mapview corousel starts here *******************************/

.hover-div .carousel-root .carousel:nth-child(2),
.hover-div .carousel .slide .legend,
.carousel-status {
    display: none !important;
}

.carousel.carousel-slider .control-arrow.control-next,
.carousel.carousel-slider .control-arrow.control-prev {
    position: absolute;
    top: -300px !important;
}

.carousel.carousel-slider .control-arrow.control-next,
.carousel.carousel-slider .control-arrow.control-prev:hover {
    background-color: transparent !important;
}

.hover-div .carousel .slider-wrapper {
    padding-top: 35px !important;
}

.hover-div .carousel canvas {
    height: 253px !important;
}

.hover-div .carousel .control-dots .dot {
    width: 24px;
    height: 4px;
    border-radius: 6px;
    color: #FFF !important;
    background-color: #FFF;
}


/****** no data section ****/
.code-compliance .noDataSection {
    margin-top: 0rem !important;
}

.code-compliance .noDataSection {
    height: 12vh !important;
}

.code-compliance .noDataSection {

    /* background-color: rgba(68, 68, 79, 0.60) !important; */
    width: 100%;
}

.code-compliance .noDataSection h2 {
    font-size: 15px !important;
    margin-top: 40px !important;
}

.code-compliance .noDataSection p,
.code-compliance .noDataSection img,
.code-compliance .noDataSection .userCreated {
    /* font-size: 9px !important; */
    /* margin-top: -20px; */
    display: none !important;
}

.code-compliance .noDataSection .heading-hidden {
    display: block;
    padding-top: 20px;
    color: #FFF;
}

.code-compliance .noDataSection {
    gap: 0px !important;
}

.graph-container {

    position: relative;
    z-index: 99999 !important;
    /* Ensure it is above other elements */
    height: 100% !important;
    /* Or any specific height */
    width: 100% !important;
    /* Or any specific width */

}
/************* css for 1440px  starts here ***************/
@media (min-width:1440px) and (max-width:1534px) {
    .mapview-modal-height {
        height: 74vh;
    }
    .custom-position {
        left: 34rem !important;
    }
}
/***************** css for 1920px starts here *****************/
@media (min-width:1920px) {
    .custom-position {
        left: 49rem !important;
    }

    .mapview-modal-height {
        height: 75vh;
        /* overflow-y: hidden; */
    }

    /* .mapview-style {
        margin-top: -38px !important;
    } */
}
