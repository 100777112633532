.Toastify__toast {
    background-color: #051B44 !important;
    border-radius: 16px;
    min-height: 47px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.Toastify__toast-body {
    padding: 0;
    margin-top: 4px;
    margin-bottom: 7px;
    padding-top: 0px;
    color: #FFF !important;
}

.Toastify__toast button {
    margin-top: 14px;
    margin-right: 5px;
    background: rgba(255, 255, 255, 0.35);
    padding: 2.5px;
    border-radius: 100%;
    height: 20px;
    width: 20px;
}
.assign-permission-toast .Toastify__toast button{
    margin-top: 20px !important;
}

.Toastify__toast button svg {
    height: 13px;
    width: 13px;
    padding-left: 3px;
 
}

.Toastify__toast-icon svg{
margin-left: -2px !important;

}

.Toastify__toast-icon {

margin-inline-end: 9px;
width: 18px;
flex-shrink: 0;
display: flex;
}
.Toastify__zoom-enter {
margin-left: -1px;
}
.Toastify__close-button svg{
    fill: #FFF !important;
    color: #FFF !important;
}
