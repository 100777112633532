.imapct-analysis .react-tabs .react-tabs__tab--selected {
  color: #4A4A68 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 12px;
  background: #FFF;

  padding: 0px 6px;
  margin-bottom: 0px !important;
}
.imapct-analysis .react-tabs__tab-list{
  border-bottom: none !important;
}

.imapct-analysis .react-tabs .react-tabs__tab {
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border: none !important;  */
  box-shadow: none !important;
  padding: 0px 6px;

  /* border-bottom: 2px solid #EFF1F7; */
}

/* .imapct-analysis .react-tabs .react-tabs__tab {
  padding: 5.5px 16.34px 5.5px 15px;
  margin: 0 4px 2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
 
  text-transform: capitalize;
} */
.react-tabs__tab {
  border: none !important;
  /* bottom: 0px !important;
  position: relative; */
}

.react-tabs .react-tabs__tab--selected {
  border: none;
  /* border-radius: 9999px; */
  /* box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
  padding: 5.5px 16.34px 5.5px 15px; */
}

.react-tabs__tab:focus:after {
  position: relative !important;
}
