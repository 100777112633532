.ReactModal__Content {
    padding: 0px !important;
    border: 0px !important;
}

.ReactModal__Overlay {
    background-color: rgba(23, 49, 74, 0.58) !important;
    z-index: 999 !important;
}
/* .ReactModal__Content{
    border-radius: 12px !important;
} */
