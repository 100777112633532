.alerts-solution .alert-text {
    color: var(--Components-Breadcrumb-Component-itemColor, rgba(0, 0, 0, 0.45));
}

.alerts-solution .explore-text {
    color: var(--Components-Breadcrumb-Component-lastItemColor, rgba(0, 0, 0, 0.88));
}

.alerts-solution .react-tabs .react-tabs__tab--selected {
    color: #377DFF !important;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    background-color: #FFF !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #377DFF !important;
    padding: 22px 16px !important;
    margin-bottom: 0px !important;
}

.alerts-solution .react-tabs .main-border {
    border-bottom: 2px solid #EFF1F7;
    margin-bottom: -2px;

}

.alerts-solution .react-tabs .react-tabs__tab,
.alerts-solution .react-tabs .react-tabs__tab:nth-child(1) {
    color: #BDC5D1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    /* border: none !important;  */
    box-shadow: none !important;
    padding: 22px 16px !important;

    /* border-bottom: 2px solid #EFF1F7; */
}

/* .alerts-solution .react-tabs .react-tabs__tab {
    padding: 5.5px 16.34px 5.5px 15px;
    margin: 0 4px 2px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
   
    text-transform: capitalize;
} */
.react-tabs__tab {
    border: none !important;
    /* bottom: 0px !important;
    position: relative; */
}

.react-tabs .react-tabs__tab--selected {
    border: none;
    /* border-radius: 9999px; */
    /* box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
    padding: 5.5px 16.34px 5.5px 15px; */
}

.react-tabs__tab:focus:after {
    position: relative !important;
}

.redbg {
    border-left: 2px solid #F12E52;
    background: rgba(241, 46, 82, 0.06);
}

.shadow-light {
    box-shadow: 0px 14px 12px 11px rgba(140, 152, 164, 0.08);
}

.margin-bottm {
    margin-bottom: 0 !important;
}

.powered-solution .react-tabs .react-tabs__tab--selected {
    background-color: transparent !important;
}

.powered-solution .react-tabs .react-tabs__tab,
.powered-solution .react-tabs .react-tabs__tab--selected,
.powered-solution .react-tabs .react-tabs__tab:nth-child(1) {
    padding: 10px 16px !important;
}

.custom-gradient {
    background: linear-gradient(90deg, #0A93FA 0.02%, #5149F6 14.08%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gpt-custom-height,.alert-powered-height{
    height: 70vh;
}

/************* css for 1440px  starts here ***************/
@media (min-width:1440px) and (max-width:1534px) {
    .alert-div .custom-height {
        height: 59vh !important;
        overflow-y: auto !important;
    }

    .powered-solution .powered-custom-height {
        height: 52.5vh !important;
        overflow-y: auto !important;
    }
    .gpt-custom-height{
        height: 65vh;
    }
    .alert-powered-height{
        height: 66vh !important;
    }
}

@media (min-width:1600px) and (max-width:1919px) {
    .alert-div .custom-height {
        height: 60vh !important;
        overflow-y: auto !important;
    }

    .powered-solution .powered-custom-height {
        height: 56.5vh !important;
        overflow-y: auto !important;
    }
}

/************* css for 1920px starts here *************/
@media (min-width:1920px) {
    .alert-div .custom-height {
        height: 63vh !important;
        overflow-y: auto !important;
    }

    .powered-solution .powered-custom-height {
        height: 58vh !important;
        overflow-y: auto !important;
    }
}
