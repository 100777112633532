@keyframes pulse {
    0% {
      transform: scale(1.0);
      opacity: 0.1;
    }
    50% {
      transform: scale(1.8);
      opacity: 0.2;
    }
    100% {
      transform: scale(1.8);
      opacity: 0.3;
    }
  }
  
  