:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

/* input:focus {
    border: 0px !important;
} */

/* .checkbox-border {
    border: 1px solid red !important;
    border-radius: 6px !important;
} */

.assign-permission-checkbox input[type="checkbox"] {
    border: 1px solid grey !important;
    margin-left: 10px;
    border-radius: 6px !important;
    width: 16px;
    height: 16px;
}

tr:nth-child(even) {
    background: #F8FAFD;
}

.role-field,
.sample-detail .select-field {
    padding: 6px 15px;
    background: rgba(0, 0, 0, 0) url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(96% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

.select-field {
    padding: 6px 15px;
    background: rgba(0, 0, 0, 0) url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(93% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.onsite-parameters select {
    /* padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 24px;  */
    padding: 6px 24px 6px 15px;
    background: rgba(0, 0, 0, 0) url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(86% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-footer {
    background: #FFF;
    box-shadow: 12px 0px 12.7px 2px rgba(140, 152, 164, 0.18);
    z-index: 10 !important;
}

.checkbox-div input {
    border: 1px solid #67778836 !important;
    border-radius: 3px !important;
}

.wq-parameter .save-btn {
    color: white !important;
}

.wq-parameter .ktjKdI {
    max-width: 26% !important;
}

#assign_user {
    padding: 6px 15px;
    background: rgba(0, 0, 0, 0) url(../../../assets/images/select-down-arrow.svg) no-repeat scroll calc(97% + 3px) center / 12px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.searchWrapper {
    border: 0px !important;
    padding: 0px !important;
}

#multiselectContainerReact .search-wrapper .searchBox::placeholder {
    color: black !important;
    /* display: none !important; */
}

/* #multiselectContainerReact .search-wrapper .searchBox::placeholder::selection{
    color: transparent;
} */
.onsite-parameters div input[type="text"] {
    padding-top: 9px !important;
    padding-bottom: 10px !important;
}

.on-site-parameter .rdt_Pagination,
.in-lab-parameter .rdt_Pagination {
    display: none !important;
}

/* .on-site-parameter .ZeRpf {
height: 40vh;

} */
.on-site-parameter .rdt_TableBody,
.in-lab-parameter .rdt_TableBody {
    height: 30vh !important;
}

@media (min-width:1024px) and (max-width:1340px) {

    /* option{
        font-size: 12px !important;
    
    } */
    .tab-visible {
        display: block !important;
    }
}

/* .onsite-parameters {
    width: 100% !important;
    font-size: 10px !important;
}
.onsite-parameters select{

    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
} */
.form-height {
    height: 85%;
}

@media (min-width:1920px) {
    .form-height {
        height: 92% !important;
    }
}
