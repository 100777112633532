.card {
  position: relative;
  width: 100%;
  padding: 0px 10px;
  /* height: 120px;
  border-radius: 10px;
  padding: 0 50px;
  margin: 50px auto;
  background-color: rgb(162, 166, 178); */
}

.scale {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 25px 10px 10px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.scale_item {
  color: #fff;
  font-family: sans-serif;
  font-weight: 300;
}
